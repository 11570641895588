@use "../variables" as variable;

.grid {
    width: 100%;
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto;

    @include variable.media(576px, min) {	
        padding: 0 40px;
    }
 
    @include variable.media(1260px, min) {	
        padding: 0 80px;
    }
}

.grid-inside {
    width: 100%;
}

