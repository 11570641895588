@use "../variables" as variable;

.coo {
    position: relative;
    padding-bottom: 30px;

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        &::after {
            content: "";
            flex-grow: 1;
            height: 1px;
            background: variable.$linear;
        }
    }

    &__title {
        margin-right: 30px;
    }
}


.coo-slider {
    overflow: hidden;

    @include variable.media(575px, max) {	
        margin: 0 30px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
}

.coo-slider-nav {
    position: absolute;
    z-index: 11;
    top: calc(50% + 15px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding-top: 3px;
    background: rgb(49,148,199);
    background: linear-gradient(72deg, rgba(49,148,199,1) 0%, rgba(37,84,177,1) 100%);
    border-radius: 50%;
    transition: all .3s;

    svg, img {
        width: 14px;
        height: auto;
    }

    &--prev {
        left: 6px;
        transform: rotate(90deg);
    }

    &--next {
        right: 6px;
        transform: rotate(-90deg);
    }
}


.filter-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -6px;
    padding-bottom: 24px;

    &__item {
        padding: 0 6px;
        margin-bottom: 12px;
    }
}


.filter-btn {
    display: inline-block;
    font-size: 1.4rem;
    padding: 8px 16px 6px;
    line-height: 1em;
    font-weight: 700;
    background-color: #395EA7;
    border-radius: 50px;

    &.active, &:hover {
        background-color: #3FB5C7;
    }
}