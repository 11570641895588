@use "../variables" as variable;

.to-about {
    padding-bottom: 80px;
}

.tab-list {
    &__item {
        margin-bottom: 20px;
    }
}

.tab-box {
    $box : &;
    width: 100%;
    border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
    border-width: 1px;
    border-style: solid;
    position: relative;

    &:hover {
        background: linear-gradient(180deg, rgba(49,148,199,0.2) 0%, rgba(37,84,177,0.2) 100%);
    }

    &__header {
        padding: 30px 40px;
    }

    &__content {
        padding: 0 40px 40px;
    }

    &__line {
        margin-bottom: 40px;
    }


    &__col-1 {
        margin-bottom: 30px;
    }

    &__col-bg {
        padding: 20px 20px;
        background: rgba(49,148,199, .2);
        background: linear-gradient(180deg, rgba(49,148,199,0.2) 0%, rgba(37,84,177,0.2) 100%);
    }

    &__content {
        display: none;
    }

    &--simple {
        .tab-box-btn {
            display: none !important;
        }
    }

    &--toggle {
        #{$box} {
            &__header {
                cursor: pointer;
            }
        }
    }
    

    &__row {
        &--t {
            padding-top: 40px;
        }
    }

    &__actions {
        padding-top: 20px;

        p {
           margin-bottom: 10px;
        }
    }

    &.active {
        #{$box} {
            &__content {
                display: block;
            }
        }
    }

    &--extra {
        background: linear-gradient(180deg, rgba(49,148,199,0.2) 0%, rgba(37,84,177,0.2) 100%);

        &::before {
            content: "";
            position: absolute;
            width: 66px;
            height: 80px;
            top: 0;
            left: 0;
            background-image: url(../../assets/vector.svg);
            background-size: contain;
            background-repeat: no-repeat;
            transform: translate(-38px, 50%);
        }

    }
}

.tab-box-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &__title {
        min-width: 320px;
        width: 320px;
    }

    &__text {
        flex-grow: 1;
        max-width: 682px;
        padding: 0 20px;
    }

    &__btn {
        width: 120px;
        min-width: 120px;
        display: flex;
        justify-content: flex-end;
    }
}


@include variable.media(576px, min) {	
    .tab-box {
        $box : &;

        &__actions {
            padding-top: 40px;
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0;
                margin-right: 30px;
            }
        }
    }
}


@include variable.media(991px, max) {	

    .tab-box {
        &__header {
            padding: 30px 20px;
        }

        &__content {
            padding: 0 20px 30px;
        }
    }

    .tab-box-header {

        &__title {
            min-width: 100%;
            width: 100%;
        }

        &__text {
            width: 100%;
            padding-right: 0;
            margin-bottom: 10px;
        }

        &__btn {
            width: 100%;
        }
    }
}


@include variable.media(992px, min) {	
    .tab-box {
        $box : &;

        &__row {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
    
            .text {
                max-width: 520px;
            }
        }

        &__col-1 {
            width: 50%;
            padding-right: 80px;
            margin-bottom: 0;
        }

        &__col-2 {
            width: 50%;
        }

        &__col-bg {
            padding: 30px 40px;
        }
    }
}


@include variable.media(1259px, max) {	
    .tab-box-header {
        flex-wrap: wrap;

        &__title {
            width: 100%;
            margin-bottom: 10px;
        }

        &__text {
            padding-left: 0;
        }
    }
}