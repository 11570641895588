@use "../variables" as variable;


.coo-porfolio {
    padding-top: 40px;
    padding-bottom: 60px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    &__item {
        width: 50%;
        padding: 0 6px;
        margin-bottom: 12px;

        &.hide {
            display: none;
        }

        @include variable.media(992px, min) {	
            width: 33.33%;
        }

        @include variable.media(1260px, min) {	
            width: 25%;
        }
    }
}

@include variable.media(992px, min) {	
    .product-list {
        margin: 0 -20px;

        &__item {
            padding: 0 20px;
            margin-bottom: 40px
        }
    }
}


.product-box {
    $box: &;
    position: relative;
    padding-bottom: 130%;

    &__img {
        @include variable.cover();
        object-fit: contain;
        object-position: center;
    }

    &__content {
        opacity: 0;
        @include variable.cover();
        background-color: rgba(49, 148, 199, .8);
        padding: 10px 8px;
        color: #fff;
        transition: all .3s;
    }

    &__info {
        margin-bottom: 12px;
    }

    &:hover {
        #{$box} {
            &__content {
                opacity: 1;
            }
        }
    }
}


.product-cat-list {


    &__item {
        margin-bottom: 5px;

        span {
            display: inline-block;
            font-size: 1.2rem;
            line-height: 1em;
            font-weight: 700;
            padding: 8px 8px 5px;
            background-color: #395EA7;
            border-radius: 50px;

            @include variable.media(364px, max) {	
                font-size: 1rem;
            }
     
        }
    }
}


@include variable.media(576px, min) {	

    .product-list {
        margin: 0 -10px;

        &__item {
            padding: 0 10px;
            margin-bottom: 20px;
        }
    }

    .product-box {
        $box: &;

        &__content {
            padding: 20px;
        }

        &__info {
            margin-bottom: 20px;
        }
    }

    .product-cat-list {
        margin-top: 10px;

        &__item {
            span {
                font-size: 1.4rem;
                padding: 8px 16px 5px;
            }
        }
    }
}