@use "../variables" as variable;

.offer-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;

    &__item {
        width: 100%;
        padding: 0 10px;
        margin-bottom: 30px;
        position: relative;

        @include variable.media(768px, min) {	
            width: 50%;
        }
    }
}


.offer-box {
    $box: &;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;

    &__title {
       display: none;
    }

    &__line {
       display: none;
    }

    &__point {
        display: none;
    }

    &__desc {
        
    }

    &.active {

    }
}


.offer-box-desc {
    width: 100%;
    padding: 26px 12px 20px;
    text-align: center;
    color: variable.$primary;

    @include variable.media(576px, min) {
        padding: 30px 20px 30px;
    }

    @include variable.media(1260px, min) {
        width: 320px;
        max-width: 320px;
        padding: 30px 20px 50px;
    }

    @include variable.media(1440px, min) {
        width: 500px;
        max-width: 500px;
        padding: 30px 30px 50px;
    }

    @include variable.media(1560px, min) {
        width: 605px;
        max-width: 605px;
        padding: 30px 40px 50px;
    }

    &__title {
        color: variable.$secondary;
        margin-bottom: 20px;

        @include variable.media(991px, max) {
            font-size: 1.8rem;
        }
    }

    &__actions {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -5px;

        a {
            margin: 0 5px 10px;
        }
    }
}


@include variable.media(992px, min) {	
    .offer-list {
        margin: 0 -16px;

        &__item {
            width: 33.33%;
            padding: 0 16px;
            margin-bottom: 40px;
        }
    }
}


@include variable.media(1259px, max) {

    .offer-box-desc { 
        color: #FFFFFF;
    }

    .offer-list {
        &__item {
            display: flex;
        }

        .offer-box {
            width: 100%;
            flex-grow: 1;
            //background-color: #fff;
            border-radius: 0;
            border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
            border-width: 1px;
            border-style: solid;
            color: #fff;

            &__title {
                font-size: 1.8rem;
            }
        }
    }
}


@include variable.media(1260px, min) {

    .offer-box-desc { 
        background-color: #FFFFFF;
        border-radius: 20px;
    }

    .offer-list {
        margin: 0;

        &__item {
            width: max-content;
            max-width: 280px;
            min-width: 220px;
            margin-bottom: 100px;

            &:nth-child(2) {
                transform: translateY(52px);
            }
    
            &:nth-child(3) {
                transform: translateY(36px);
            }
    
            &:nth-child(4) {
                transform: translateY(14px);
            }
    
            &:nth-child(6) {
                transform: translateY(80px);
            }
    
            &:nth-child(7) {
                transform: translateY(40px);
            }
    
            &:nth-child(8) {
                transform: translateY(74px);
            }
    
            &:nth-child(9) {
                transform: translateY(10px);
            }
    
            &.active {
                z-index: 3;
            }
        }
    }


    .offer-box {
        $box: &;

        &__title {
            display: block;
            text-align: center;
            margin-bottom: 26px;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 1.5em;
        }

        &__line {
            display: block;
            width: 1px;
            height: 58px;
            margin-bottom: 5px;
            background: rgb(63,181,199);
            background: linear-gradient(180deg, rgba(63,181,199,1) 0%, rgba(57,94,167,1) 100%);
        }

        &__point {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: rgb(49,148,199);
            background: linear-gradient(90deg, rgba(49,148,199,1) 0%, rgba(37,84,177,1) 100%);
            filter: drop-shadow(5px 5px 20px rgba(71,198,218,.5) );
            transition: all .3s;
            color: transparent;
            font-size: 1.2rem;
            font-weight: 400;
            position: relative;
        }

        &:hover {
            #{$box} {
                &__point {
                    color: #fff;
                    transform: scale(2.4);
                }
            }
        }

        &__desc {
            position: absolute;
            bottom: 12px;
            left: 50%;
            transform: translateX(-50%);
            //display: none;
            opacity: 0;
            transition: variable.$transition;
            pointer-events: none;
        }

        &.active {
            #{$box} {
                &__point {
                    color: #fff;
                    transform: scale(2.4);
                    z-index: 2;
                }
    
                &__desc {
                    //display: block;
                    opacity: 1;
                    pointer-events: all;
                }
            
            }
        }
    }

}