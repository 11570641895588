@use "../../variables" as variable;

.nav {

}

.hamburger {
	display: none;
	$size: 40px;
	width: $size;
	height: $size;
	z-index: 100;
	position: relative;
	cursor: pointer;
	margin-left: 20px;
	overflow: hidden;

	svg {
		position: absolute;
		right: -15%;
		height: 100%;
		transform-origin: top right;
	}

	path {
		fill: none;
		stroke: #fff;
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
		--length: 24;
		--offset: -38;
		stroke-dasharray: var(--length) var(--total-length);
		stroke-dashoffset: var(--offset);

		&:nth-child(1),
		&:nth-child(3) {
			--total-length: 126.64183044433594;
		}

		&:nth-child(2) {
			--total-length: 70;
		}
	}
}


@include variable.media(1259.99px, max) {	

	.nav-toggled {

		.header {
			box-shadow: 0px 10px 30px 0px rgba(71, 198, 218, 0.2);

			&__langs {
				transform: translateX(-50%);
				opacity: 1;
				pointer-events: all;
			}
		}
	
		.nav {
			transform: translateX(0);
			opacity: 1;
		}
	
		.hamburger {
			path {
				stroke: #fff;
	
				&:nth-child(1),
				&:nth-child(3) {
					--length: 22.627416998;
					--offset: -94.1149185097;
				}
	
				&:nth-child(2) {
					--length: 0;
					--offset: -50;
				}
			}
		}
	}

    .nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
		height: 100vh;
		min-height: 600px;
		background-color: #121326;
        opacity: 0;
        transform: translateX(100%);
        transition: variable.$transition;
		display: flex;
        align-items: center;
        justify-content: center;
    }

	.hamburger {
		display: block;
	}
}