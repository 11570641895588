@use "../variables" as variable;

.contact {
  
    &__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        position: relative;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: variable.$linear;
            margin-bottom: 100px;
        }
    }

    &__dec {
        position: absolute;
        top: -15px;
        left: 60px;
        width: 80px;
        height: auto;
    }


    &__form {
        margin-bottom: 40px;
    }

    &__info {
        max-width: 360px;
        padding-top: 16px;
        margin-bottom: 40px;
    }

    &__address {
        margin-bottom: 20px;
    }

    &__text {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.5em;
        margin-bottom: 80px;
    }

    &__email {
        display: flex;
        padding-top: 40px;
    }
}


@include variable.media(992px, min) {	
    .contact {

        &__header {
            margin-bottom: 90px;
            align-items: flex-end;

            &::before {
                margin-bottom: 130px;
            }
        }

        &__dec {
            top: -15px;
            left: 60px;
            width: 140px;
        }

        &__row {
            display: flex;
            justify-content: space-between;
        }

        &__form {
            order: 2;
            width: 62.5%;
        }

        &__email {
            padding-top: 80px;
            justify-content: flex-end;
        }
    }
}