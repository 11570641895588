@use "../../variables" as variable;

.th-about {
    position: relative;

    &__bg {
        position: absolute;
        top: 340px;
        left: 0;
        width: 100%;
        height: auto;
    }

    &__container {
        position: relative;
        padding-top: 140px;

        @include variable.media(575px, max) {
            padding-top: 100px;
        }
    }

    &__dec {
        position: absolute;
        top: -80px;
        right: 0;
        width: 168px;

        @include variable.media(575px, max) {
            top: -10px;
            right: -20px;
            width: 100px;
        }

        @include variable.media(992px, min) {
            top: 0;
        }
    }

    &__header {
        max-width: 960px;
        margin: 0 auto;
        position: relative;
    }

    &__list {
        padding-top: 40px;
        padding-bottom: 60px;
        max-width: 1220px;
        margin: 0 auto;

        @include variable.media(992px, min) {
            padding-top: 80px;
            padding-bottom: 120px;
        }
    }
}