@use "../variables" as variable;

.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #4A4C69;
    font-size: 1.4rem;
    font-weight: 400;

    &__item {
        display: inline-flex;

        &::after {
            content: "";
            width: 10px;
            height: 10px;
            margin: 0 8px;
            background-image: url(../../assets/next.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        &:last-child {
            color: #47C6DA;

            &::after {
                content: none;
            }
        }
    }

    a:hover {
        color: #47C6DA;
    }
}