@use "../../variables" as variable;

.header {
	width: 100%;
	position: absolute;
	z-index: 12;
	transition: variable.$transition;
	overflow: hidden;
	
	&__row {
		display: flex;
		align-items: center;
		height: 100px;
		//position: relative;
	}

	&__branding {
		margin-right: 80px;
		position: relative;
		z-index: 2;
	}

	&__nav {
		display: flex;
		align-items: flex-end;
	}

	&__actions {
		margin-left: auto;
		display: flex;
		align-items: center;
	}
}


@include variable.media(1259.99px, max) {	
	.header {
		height: 100vh;
		min-height: 600px;
		pointer-events: none;

		&__row {
			pointer-events: all;
		}

		&__langs {
			position: absolute;
			bottom: 60px;
			left: 50%;
			opacity: 0;
			pointer-events: none;
			transition: variable.$transition;
			transform: translateX(50vw);
		}
	}
}


@include variable.media(1260px, min) {	
	.header {
		&__langs {
			margin-right: -70px;
			margin-left: 30px;
		}
	}
}

@include variable.media(1560px, min) {	
	.header {
		&__langs {
			margin-right: -120px;
			margin-left: 40px;
		}
	}
}