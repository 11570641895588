@use "../variables" as variable;

.text {
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.5em;
	text-transform: none;
	text-decoration: none;

	
	@include variable.media(576px, min) {
		font-size: 1.6rem;
	}

	@include variable.media(1440px, min) {
		font-size: 1.8rem;
	}

	b, strong {
		font-weight: 700;
	}
	
	h1, h2, h3, h4, h5, h6 {
		font-weight: 700;
		line-height: 1.25em;
		margin-bottom: 2rem;

		@include variable.media(576px, min) {	
			margin-bottom: 4rem;
		}
	}

	h1 {
		font-size: 3.6rem;
		line-height: 1.2em;
		font-weight: 700;
		text-transform: uppercase;

		@include variable.media(576px, min) {	
			font-size: 4rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 5.8rem;
		}
	}

	h2 {
		font-size: 2rem;
		line-height: 1.25em;
		font-weight: 400;

		@include variable.media(576px, min) {	
			font-size: 3.2rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 4.6rem;
		}
	}

	h3 {
		font-size: 1.8rem;
		line-height: 1.35em;
		font-weight: 400;

		@include variable.media(576px, min) {	
			font-size: 2.2rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 2.8rem;
		}
	}

	h4 {
		font-size: 16px;
		line-height: 1.5em;

		@include variable.media(768px, min) {
			font-size: 2.2rem;
		}
	}

	p {
		margin-bottom: 20px;

		@include variable.media(576px, min) {	
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	ol {
		list-style: decimal;
		padding-left: 18px;

		& > li {
			margin-top: 10px;

			@include variable.media(576px, min) {	
				margin-top: 16px;
			}
		}
	}

	ul {
		list-style: none;

		li {
			padding-left: 40px;
			margin-top: 10px;
			position: relative;

			@include variable.media(576px, min) {	
				margin-top: 16px;
			}

			&::before {
				content: "";
				width: 22px;
				height: 22px;
				position: absolute;
				top: 2px;
				left: 0;
				background-image: url(../../assets/check.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}
		}
	}

	img {
		height: auto;
	}

	a {
		font-weight: 400;
		text-decoration: underline;
	}

	hr {
		display: block;
		width: 100%;
		border-bottom: 1px solid #d0dfc0;
		margin-bottom: 40px;
		margin-top: 40px;
	}


	iframe {
		display: block;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
	}

	// a[href^="tel:"], a[href^="mailto:"], a[href^="https://www.google.com/maps/"] {
	// 	display: block;
	// 	font-size: 2rem;
	// 	font-weight: 500;

    //     @include variable.media(576px, min) {	
    //         font-size: 2.6rem;
    //     }

	// 	@include variable.media(1200px, min) {	
	// 		font-size: 3.6rem;
	// 	}

	// 	&:hover {
	// 		text-decoration: underline;
	// 	}
	// }


	&--b {
		h1, h2, h3, h4, h5, h6, b, strong {
			//font-weight: 400;
			background: -webkit-linear-gradient(-85deg,#47C6DA, #2554B1);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			width: max-content;
			max-width: 100%;
		}
	}

}
