@use "../../variables" as variable;

.footer {
    position: relative;

    &__up {
        position: absolute;
        right: 10px;
        top: -30px;

        @include variable.media(576px, min) {	
            top: 26px;
        }

        @include variable.media(1260px, min) {	
            right: 0;
        }
    }
}


.footer-copyright {
    position: relative;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    color: #3FB5C7;

    @include variable.media(1259px, max) {	
        padding-right: 30px;
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: variable.$linear;
        position: absolute;
        top: 0;
        left: 0;
    }
    
}


@include variable.media(765px, max) {	
    .footer-copyright {
        padding: 30px 0;
        flex-direction: column;

        &__info {
            margin-bottom: 16px;
        }
    }
}