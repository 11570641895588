@use "../variables" as variable;

.single-thumb {
    position: relative;
    width: 100%;
    padding-bottom: 64.8%;

    img {
        @include variable.cover();
        object-fit: cover;
    }
}