@use "../variables" as variable;

.lang-switcher {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1.4rem;
    font-weight: 700;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     width: 1px;
    //     height: 100%;
    //     background-color: #fff;
    // }

    .lang-item {

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            
            &:hover {
                color: #3FB5C7;
            }
        }

        &.current-lang {
            border: 1px solid #3FB5C7;
            border-radius: 50%;
            overflow: hidden;
        }

    }
}