@use "../variables" as variable;

.hero {
    padding-top: 120px;
    padding-bottom: 40px;
    position: relative;

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__breadcrumbs {
        margin-bottom: 40px;
        position: relative;
    }

    &__header {
        margin-bottom: 40px;
        position: relative;

        a {
            margin-top: 16px;
        }
    }

    &__row {
        position: relative;
    }

    &__title {
        max-width: 780px;
    }

    &__desc {
        margin-bottom: 30px;
    }

    &__shadow {
        position: absolute;
        top: -80px;
        left: 0;
        transform: translateX(-50%);
    }
}


@include variable.media(992px, min) {	
    .hero {
        padding-top: 120px;
        padding-bottom: 100px;

        &__shadow {
            top: -260px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            a {
                margin-top: 0;
            }
        }
    }
}


@include variable.media(1260px, min) {	
    .hero {
        padding-top: 120px;
        padding-bottom: 100px;

        &__breadcrumbs {
            margin-bottom: 80px;
        }

        &__row {
            display: flex;
        }

        &__desc {
            width: 50%;
            margin-bottom: 0;
        }
    
        &__info {
            width: 50%;
            padding-left: 80px;
        }
    }
}