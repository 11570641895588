@use "../variables" as variable;

.tp-about {
    margin-bottom: 40px;
    position: relative;

    &__box {
        padding: 30px 20px;
        background: linear-gradient(-25deg, rgba(63,181,199,.2) 0%, rgba(57,94,167,.2) 100%);;
        border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
        border-width: 1px;
        border-style: solid;

        &--simple {
            background: transparent;
        }
    }

    &__info {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #47C6DA;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.5em;
        text-transform: uppercase;

        @include variable.media(576px, min) {	
            font-size: 1.6rem;
        }

        @include variable.media(992px, min) {	
            font-size: 1.8rem;
        }
    }

    &__text {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #47C6DA;
    }


    &__col-1 {
        margin-bottom: 30px;
    }

    &__cd {
        padding-top: 30px;
    }
}


@include variable.media(992px, min) {	
    .tp-about {
        &__box {
            padding: 40px;
        }

        &__row {
            display: flex;
            margin: 0 -40px;
        }

        &__col-1 {
            width: 50%;
            padding: 0 40px;
            margin-bottom: 0;
        }

        &__col-2 {
            width: 50%;
            padding: 0 40px;
        }
    }
}