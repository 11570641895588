@use "../../variables" as variable;

.menu {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: 0 -18px;

    &__item {
        position: relative;
        padding: 0 18px;

        &.active {
            color: #3FB5C7;
        }
    }

    &__link {
        display: inline-flex;
        position: relative;
        padding: 10px 0;

        &::after {
            content: "";
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: 0;
            width: 46px;
            height: 1px;
            background: variable.$linear;
        }

        &:hover {
            color: #3FB5C7;
        }
    }
}


@include variable.media(1259.99px, max) {	
    .menu {
        flex-direction: column;

        &__item {
            margin-bottom: 30px;
        }

        &__link {
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

@include variable.media(1260px, min) {	
   
}
