@use "../variables" as variable;

.social-media {
    display: flex;
    margin: 0 -4px;

    &__item {
        padding: 0 4px;
    }

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: variable.$linear;
        border-radius: 50%;

        img {
            transition: all .3s;
        }

        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
}