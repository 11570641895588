@use "../variables" as variable;

.tof-list {
    position: relative;
    padding-bottom: 60px;
    margin-bottom: 20px;

    &__bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
    }
}

.of-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;

    &__item {
        display: flex;
        width: 100%;
        padding: 0 16px;
        margin-bottom: 32px;

        @include variable.media(992px, min) {	
            width: 50%;
        }

        &--contact {
            margin-left: auto;
            align-items: flex-end;
            justify-content: center;
        }
    }
}


.of-box {
    display: block;
    width: 100%;
    padding: 30px 20px;
    position: relative;
    border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
    border-width: 1px;
    border-style: solid;

    @include variable.media(576px, min) {	
        padding: 40px;
    }

    //background: rgb(49,148,199);
    background: linear-gradient(135deg, rgba(49,148,199,.2) 0%, rgba(37,84,177,.2) 100%);
    

    &__title {
        margin-bottom: 20px;
        position: relative;
    }

    &__text {
        position: relative;
    }
}


.tof-contact-box {
    max-width: 480px;
    padding-top: 40px;

    &__title {
        margin-bottom: 30px;
    }
}

