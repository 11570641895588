@use "../../variables" as variable;

.th-hero {
    padding-top: 140px;
    position: relative;

    &__bg {
        position: absolute;
        top: -40px;
        right: -60px;
        max-width: 100%;
        height: auto;
    }


    &__pic {
        display: none;
    }

    &__desc {
 
    }

    &__title {
        margin-bottom: 3rem;
    }
}


.th-hero-pic {
    animation: rotating 300s linear infinite;
}


@keyframes rotating {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}


@include variable.media(992px, min) {	
	.th-hero {
        padding-top: 160px;

        &__bg {
            top: -240px;
            left: 50%;
            right: auto;
            transform: translateX(-55%);
        }

        &__row {
            display: flex;
            align-items: center;
            margin: 0 -4rem;
        }

        &__pic {
            display: block;
            width: 50%;
            padding: 0 4rem;
        }

        &__desc {
            width: 50%;
            padding: 0 4rem;
        }

        &__title {
            margin-bottom: 4rem;
        }
    }

}