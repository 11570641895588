@use "../variables" as variable;

html {
	font-size: 62.5%; //10px
	//font-size: 0.5208333333333333vw;
}

body {
	font-family: variable.$font_primary;
	color: #ffffff;
	font-size: 1.6rem; //16px
	background-color: #121326;

	@include variable.media(992px, min) {	
		background-image: url(../../assets/bg2.svg);
		background-repeat: no-repeat;
		background-position: bottom -140px right;
	}
}

main {
	overflow: hidden;
}


.center {
	text-align: center;
}


.relative {
	position: relative;
}


a,
button {
	cursor: pointer;
}


.title {
	line-height: 1.25em;
	font-weight: 600;

	&--1 {
		font-size: 3.6rem;
		line-height: 1.2em;
		font-weight: 700;
		text-transform: uppercase;

		@include variable.media(576px, min) {	
			font-size: 4rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 5.8rem;
		}
	}

	&--2 {
		font-size: 2rem;
		line-height: 1.25em;
		font-weight: 400;

		@include variable.media(576px, min) {	
			font-size: 3.2rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 4.6rem;
		}
	}

	&--3 {
		font-size: 1.8rem;
		line-height: 1.35em;
		font-weight: 400;

		@include variable.media(576px, min) {	
			font-size: 2.2rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 2.8rem;
		}
	}

	&--4 {
		font-size: 16px;
		line-height: 1.5em;

		@include variable.media(768px, min) {
			font-size: 2.2rem;
		}
	}

	b, strong {
		background: -webkit-linear-gradient(45deg,#47C6DA, #2554B1);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.text-gradent {
	background: -webkit-linear-gradient(-85deg,#47C6DA, #2554B1);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	width: max-content;
	max-width: 100%;
}

.section-header {
	max-width: 960px;
	margin: 0 auto;
	text-align: center;
	
	&__title {
		margin-bottom: 20px;
	}
}