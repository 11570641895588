@use "../variables" as variable;

::-webkit-input-placeholder { /* Edge */
    color: #ffffff;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
}
::placeholder {
    color: #ffffff;
}


input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
    font-family: inherit;
    font-size: 1.4rem;
    line-height: 1.5em;
    width: 100%;
    font-weight: 400;
    color: #ffffff;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}


input[type=radio] {
    min-width: 2.2rem;
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid variable.$primary;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
 

    &:checked {
        background-color: variable.$secondary;
        border-color: variable.$secondary;
    }
}

input[type=checkbox] {
    min-width: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid #ffffff;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
 
    &::after {
        content: "";
        width: 9px;
        height: 9px;
        background-color: #ffffff;
        transform: scale(0);
        transition: all 0.3s;
    }

    &:checked {
        &::after {
            transform: scale(1);
        }
    }
}


.wpcf7-list-item label {
    display: flex;
    color: #3FB5C7;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5em;
    padding-top: 4px;


    a {
        text-decoration: underline;
    }

    input {
        margin-right: 16px;
        margin-top: 3px;
    }
}


input[type=submit] {
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 700;   
    cursor: pointer;
}


.wpcf7-form-control-wrap {
    display: block;
    position: relative;
    width: 100%;
}


.wpcf7-not-valid-tip {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 1.2rem;
}

.screen-reader-response {
    display: none;
}

.wpcf7-response-output {
    background: variable.$linear;
    padding: 12px 10px;
    text-align: center;
    color: #fff;
    border-radius: 25px;
    margin-top: 2rem;
    font-size: 14px;
    line-height: 1.4em;

    @include variable.media(576px, min) {
        border-radius: 50px;
        margin-top: 3rem;
        padding: 20px;
        font-size: 16px;
    }

    &:empty {
        display: none;
    }
}


.form-label {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    padding: 16px 0;

    svg,img {
        margin-right: 20px;
    }
}


.form-submit {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
}

.form-field {
    margin-bottom: 10px;
}

@include variable.media(768px, min) {	

    // input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
    //     font-size: 1.8rem;
    //     padding: 2.4rem 0;
    // }

    .form-fields {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px
    }
    
    .form-field {
        width: 33.33%;
        padding: 0 10px;
        margin-bottom: 10px;
    
        &--full {
            width: 100%;
        }
    }
}