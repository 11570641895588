@use "../variables" as variable;

.btn {
    display: inline-flex;
    align-items: baseline;
    //min-height: 45px;
    font-size: 1.4rem;
    line-height: 1em;
    font-weight: 700;
    padding: 12px 20px 10px;
    border-radius: 50px;
    background: rgb(63,181,199);
    background: linear-gradient(90deg, rgba(63,181,199,1) 0%, rgba(57,94,167,1) 47%, rgba(61,143,185,1) 100%);
    color: #ffffff;
    position: relative;
    overflow: hidden;
    cursor: pointer;



    @include variable.media(576px, min) {
        font-size: 1.6rem;
        padding: 16px 28px 12px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: rgb(63,181,199);
        background: linear-gradient(90deg, rgba(63,181,199,1) 0%, rgba(57,94,167,1) 47%, rgba(61,143,185,1) 100%);
        border-radius: 49px;
        transition: variable.$transition;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(63,181,199,1) 54%, rgba(255,255,255,0) 100%);
        border-radius: 50px;
        transition: variable.$transition;
    }

    span, img, svg, input {
        position: relative;
        z-index: 2;
    }

    img, svg {
        margin-left: 16px;
    }

    &:hover {
        &::before {
            background-color: #ffffff;
        }

        &::after {
            opacity: 0.9;
        }
    }
}


.btn-up {
    width: 45px;
    min-width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: variable.$linear;
    border-radius: 50%;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: #121326;
        border-radius: 50%;
        transition: all .3s;
    }

    img, svg {
        position: relative;
        z-index: 2;
        transition: all .3s;
    }

    &:hover {
        &::after {
            opacity: 0;
        }

        svg path {
            fill: #ffffff;
        }
    }
}



.tab-box-btn {
    $btn : &;
    display: inline-flex;
    align-items: center;

    &__arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        padding-top: 3px;
        background: rgb(49,148,199);
        background: linear-gradient(72deg, rgba(49,148,199,1) 0%, rgba(37,84,177,1) 100%);
        border-radius: 50%;
        transition: all .3s;

        svg, img {
            width: 14px;
            height: auto;
        }
    }

    .toggle {
        display: none;
    }    

    &.active {

        #{$btn} {
            &__arrow {
                transform: rotate(180deg);
            }
        }

        .expand {
            display: none;
        }

        .toggle {
            display: inline-block;
        } 
    }
}


.btn-ue {
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;
    transition: all .3s;

    span {
        margin-right: 20px;

        @include variable.media(1439px, max) {
            display: none;
        }
    }

    img {
        transition: all .3s;
    }

    &:hover {
        //color: #3FB5C7;
        
        img {
            transform: scale(1.1);
        }
    }
}


@include variable.media(576px, min) {

}