@import url("https://use.typekit.net/ybo1rdd.css");
*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

img,
article,
main,
aside,
details,
figcaption,
figure,
footer,
header,
nav {
  display: block;
}

address {
  font-style: inherit;
}

img {
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}

li:empty,
p:empty {
  display: none;
}

textarea,
select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  background: transparent;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
}

:focus,
:active {
  outline: none;
}

/*
* Sets element media query
*/
/*
* Fills relative parent width and height
*/
/*
* Centers element by type
*/
html {
  font-size: 62.5%;
}

body {
  font-family: "azo-sans-web", sans-serif;
  color: #ffffff;
  font-size: 1.6rem;
  background-color: #121326;
}
@media screen and (min-width: 992px) {
  body {
    background-image: url(../../assets/bg2.svg);
    background-repeat: no-repeat;
    background-position: bottom -140px right;
  }
}

main {
  overflow: hidden;
}

.center {
  text-align: center;
}

.relative {
  position: relative;
}

a,
button {
  cursor: pointer;
}

.title {
  line-height: 1.25em;
  font-weight: 600;
}
.title--1 {
  font-size: 3.6rem;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (min-width: 576px) {
  .title--1 {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1260px) {
  .title--1 {
    font-size: 5.8rem;
  }
}
.title--2 {
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 400;
}
@media screen and (min-width: 576px) {
  .title--2 {
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 1260px) {
  .title--2 {
    font-size: 4.6rem;
  }
}
.title--3 {
  font-size: 1.8rem;
  line-height: 1.35em;
  font-weight: 400;
}
@media screen and (min-width: 576px) {
  .title--3 {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1260px) {
  .title--3 {
    font-size: 2.8rem;
  }
}
.title--4 {
  font-size: 16px;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .title--4 {
    font-size: 2.2rem;
  }
}
.title b, .title strong {
  background: -webkit-linear-gradient(45deg, #47C6DA, #2554B1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradent {
  background: -webkit-linear-gradient(-85deg, #47C6DA, #2554B1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: max-content;
  max-width: 100%;
}

.section-header {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.section-header__title {
  margin-bottom: 20px;
}

.text {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5em;
  text-transform: none;
  text-decoration: none;
}
@media screen and (min-width: 576px) {
  .text {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 1440px) {
  .text {
    font-size: 1.8rem;
  }
}
.text b, .text strong {
  font-weight: 700;
}
.text h1, .text h2, .text h3, .text h4, .text h5, .text h6 {
  font-weight: 700;
  line-height: 1.25em;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .text h1, .text h2, .text h3, .text h4, .text h5, .text h6 {
    margin-bottom: 4rem;
  }
}
.text h1 {
  font-size: 3.6rem;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (min-width: 576px) {
  .text h1 {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1260px) {
  .text h1 {
    font-size: 5.8rem;
  }
}
.text h2 {
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 400;
}
@media screen and (min-width: 576px) {
  .text h2 {
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 1260px) {
  .text h2 {
    font-size: 4.6rem;
  }
}
.text h3 {
  font-size: 1.8rem;
  line-height: 1.35em;
  font-weight: 400;
}
@media screen and (min-width: 576px) {
  .text h3 {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1260px) {
  .text h3 {
    font-size: 2.8rem;
  }
}
.text h4 {
  font-size: 16px;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .text h4 {
    font-size: 2.2rem;
  }
}
.text p {
  margin-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .text p {
    margin-bottom: 30px;
  }
  .text p:last-child {
    margin-bottom: 0;
  }
}
.text p:last-child {
  margin-bottom: 0;
}
.text ol {
  list-style: decimal;
  padding-left: 18px;
}
.text ol > li {
  margin-top: 10px;
}
@media screen and (min-width: 576px) {
  .text ol > li {
    margin-top: 16px;
  }
}
.text ul {
  list-style: none;
}
.text ul li {
  padding-left: 40px;
  margin-top: 10px;
  position: relative;
}
@media screen and (min-width: 576px) {
  .text ul li {
    margin-top: 16px;
  }
}
.text ul li::before {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  top: 2px;
  left: 0;
  background-image: url(../../assets/check.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.text img {
  height: auto;
}
.text a {
  font-weight: 400;
  text-decoration: underline;
}
.text hr {
  display: block;
  width: 100%;
  border-bottom: 1px solid #d0dfc0;
  margin-bottom: 40px;
  margin-top: 40px;
}
.text iframe {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}
.text--b h1, .text--b h2, .text--b h3, .text--b h4, .text--b h5, .text--b h6, .text--b b, .text--b strong {
  background: -webkit-linear-gradient(-85deg, #47C6DA, #2554B1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: max-content;
  max-width: 100%;
}

.grid {
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (min-width: 576px) {
  .grid {
    padding: 0 40px;
  }
}
@media screen and (min-width: 1260px) {
  .grid {
    padding: 0 80px;
  }
}

.grid-inside {
  width: 100%;
}

.btn {
  display: inline-flex;
  align-items: baseline;
  font-size: 1.4rem;
  line-height: 1em;
  font-weight: 700;
  padding: 12px 20px 10px;
  border-radius: 50px;
  background: rgb(63, 181, 199);
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 47%, rgb(61, 143, 185) 100%);
  color: #ffffff;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
@media screen and (min-width: 576px) {
  .btn {
    font-size: 1.6rem;
    padding: 16px 28px 12px;
  }
}
.btn::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: rgb(63, 181, 199);
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 47%, rgb(61, 143, 185) 100%);
  border-radius: 49px;
  transition: all 0.25s ease-in-out;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(63, 181, 199) 54%, rgba(255, 255, 255, 0) 100%);
  border-radius: 50px;
  transition: all 0.25s ease-in-out;
}
.btn span, .btn img, .btn svg, .btn input {
  position: relative;
  z-index: 2;
}
.btn img, .btn svg {
  margin-left: 16px;
}
.btn:hover::before {
  background-color: #ffffff;
}
.btn:hover::after {
  opacity: 0.9;
}

.btn-up {
  width: 45px;
  min-width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
  border-radius: 50%;
  position: relative;
}
.btn-up::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: #121326;
  border-radius: 50%;
  transition: all 0.3s;
}
.btn-up img, .btn-up svg {
  position: relative;
  z-index: 2;
  transition: all 0.3s;
}
.btn-up:hover::after {
  opacity: 0;
}
.btn-up:hover svg path {
  fill: #ffffff;
}

.tab-box-btn {
  display: inline-flex;
  align-items: center;
}
.tab-box-btn__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  padding-top: 3px;
  background: rgb(49, 148, 199);
  background: linear-gradient(72deg, rgb(49, 148, 199) 0%, rgb(37, 84, 177) 100%);
  border-radius: 50%;
  transition: all 0.3s;
}
.tab-box-btn__arrow svg, .tab-box-btn__arrow img {
  width: 14px;
  height: auto;
}
.tab-box-btn .toggle {
  display: none;
}
.tab-box-btn.active .tab-box-btn__arrow {
  transform: rotate(180deg);
}
.tab-box-btn.active .expand {
  display: none;
}
.tab-box-btn.active .toggle {
  display: inline-block;
}

.btn-ue {
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  transition: all 0.3s;
}
.btn-ue span {
  margin-right: 20px;
}
@media screen and (max-width: 1439px) {
  .btn-ue span {
    display: none;
  }
}
.btn-ue img {
  transition: all 0.3s;
}
.btn-ue:hover img {
  transform: scale(1.1);
}

::-webkit-input-placeholder { /* Edge */
  color: #ffffff;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ffffff;
}

::placeholder {
  color: #ffffff;
}

input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 1.5em;
  width: 100%;
  font-weight: 400;
  color: #ffffff;
}
input[type=text].disabled, input[type=email].disabled, input[type=tel].disabled, input[type=password].disabled, textarea.disabled {
  opacity: 0.5;
  pointer-events: none;
}

input[type=radio] {
  min-width: 2.2rem;
  width: 2.2rem;
  height: 2.2rem;
  border: 1px solid #1E2142;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type=radio]:checked {
  background-color: #3D8FB9;
  border-color: #3D8FB9;
}

input[type=checkbox] {
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type=checkbox]::after {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #ffffff;
  transform: scale(0);
  transition: all 0.3s;
}
input[type=checkbox]:checked::after {
  transform: scale(1);
}

.wpcf7-list-item label {
  display: flex;
  color: #3FB5C7;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5em;
  padding-top: 4px;
}
.wpcf7-list-item label a {
  text-decoration: underline;
}
.wpcf7-list-item label input {
  margin-right: 16px;
  margin-top: 3px;
}

input[type=submit] {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
}

.wpcf7-form-control-wrap {
  display: block;
  position: relative;
  width: 100%;
}

.wpcf7-not-valid-tip {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 1.2rem;
}

.screen-reader-response {
  display: none;
}

.wpcf7-response-output {
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
  padding: 12px 10px;
  text-align: center;
  color: #fff;
  border-radius: 25px;
  margin-top: 2rem;
  font-size: 14px;
  line-height: 1.4em;
}
@media screen and (min-width: 576px) {
  .wpcf7-response-output {
    border-radius: 50px;
    margin-top: 3rem;
    padding: 20px;
    font-size: 16px;
  }
}
.wpcf7-response-output:empty {
  display: none;
}

.form-label {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  padding: 16px 0;
}
.form-label svg, .form-label img {
  margin-right: 20px;
}

.form-submit {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.form-field {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .form-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .form-field {
    width: 33.33%;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .form-field--full {
    width: 100%;
  }
}
.single-thumb {
  position: relative;
  width: 100%;
  padding-bottom: 64.8%;
}
.single-thumb img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.offer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
}
.offer-list__item {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 30px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .offer-list__item {
    width: 50%;
  }
}

.offer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.offer-box__title {
  display: none;
}
.offer-box__line {
  display: none;
}
.offer-box__point {
  display: none;
}
.offer-box-desc {
  width: 100%;
  padding: 26px 12px 20px;
  text-align: center;
  color: #1E2142;
}
@media screen and (min-width: 576px) {
  .offer-box-desc {
    padding: 30px 20px 30px;
  }
}
@media screen and (min-width: 1260px) {
  .offer-box-desc {
    width: 320px;
    max-width: 320px;
    padding: 30px 20px 50px;
  }
}
@media screen and (min-width: 1440px) {
  .offer-box-desc {
    width: 500px;
    max-width: 500px;
    padding: 30px 30px 50px;
  }
}
@media screen and (min-width: 1560px) {
  .offer-box-desc {
    width: 605px;
    max-width: 605px;
    padding: 30px 40px 50px;
  }
}
.offer-box-desc__title {
  color: #3D8FB9;
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .offer-box-desc__title {
    font-size: 1.8rem;
  }
}
.offer-box-desc__actions {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
}
.offer-box-desc__actions a {
  margin: 0 5px 10px;
}

@media screen and (min-width: 992px) {
  .offer-list {
    margin: 0 -16px;
  }
  .offer-list__item {
    width: 33.33%;
    padding: 0 16px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1259px) {
  .offer-box-desc {
    color: #FFFFFF;
  }
  .offer-list__item {
    display: flex;
  }
  .offer-list .offer-box {
    width: 100%;
    flex-grow: 1;
    border-radius: 0;
    border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
    border-width: 1px;
    border-style: solid;
    color: #fff;
  }
  .offer-list .offer-box__title {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1260px) {
  .offer-box-desc {
    background-color: #FFFFFF;
    border-radius: 20px;
  }
  .offer-list {
    margin: 0;
  }
  .offer-list__item {
    width: max-content;
    max-width: 280px;
    min-width: 220px;
    margin-bottom: 100px;
  }
  .offer-list__item:nth-child(2) {
    transform: translateY(52px);
  }
  .offer-list__item:nth-child(3) {
    transform: translateY(36px);
  }
  .offer-list__item:nth-child(4) {
    transform: translateY(14px);
  }
  .offer-list__item:nth-child(6) {
    transform: translateY(80px);
  }
  .offer-list__item:nth-child(7) {
    transform: translateY(40px);
  }
  .offer-list__item:nth-child(8) {
    transform: translateY(74px);
  }
  .offer-list__item:nth-child(9) {
    transform: translateY(10px);
  }
  .offer-list__item.active {
    z-index: 3;
  }
  .offer-box__title {
    display: block;
    text-align: center;
    margin-bottom: 26px;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.5em;
  }
  .offer-box__line {
    display: block;
    width: 1px;
    height: 58px;
    margin-bottom: 5px;
    background: rgb(63, 181, 199);
    background: linear-gradient(180deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
  }
  .offer-box__point {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: rgb(49, 148, 199);
    background: linear-gradient(90deg, rgb(49, 148, 199) 0%, rgb(37, 84, 177) 100%);
    filter: drop-shadow(5px 5px 20px rgba(71, 198, 218, 0.5));
    transition: all 0.3s;
    color: transparent;
    font-size: 1.2rem;
    font-weight: 400;
    position: relative;
  }
  .offer-box:hover .offer-box__point {
    color: #fff;
    transform: scale(2.4);
  }
  .offer-box__desc {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.25s ease-in-out;
    pointer-events: none;
  }
  .offer-box.active .offer-box__point {
    color: #fff;
    transform: scale(2.4);
    z-index: 2;
  }
  .offer-box.active .offer-box__desc {
    opacity: 1;
    pointer-events: all;
  }
}
.coo {
  position: relative;
  padding-bottom: 30px;
}
.coo__header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.coo__header::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
}
.coo__title {
  margin-right: 30px;
}

.coo-slider {
  overflow: hidden;
}
@media screen and (max-width: 575px) {
  .coo-slider {
    margin: 0 30px;
  }
}
.coo-slider__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.coo-slider-nav {
  position: absolute;
  z-index: 11;
  top: calc(50% + 15px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  padding-top: 3px;
  background: rgb(49, 148, 199);
  background: linear-gradient(72deg, rgb(49, 148, 199) 0%, rgb(37, 84, 177) 100%);
  border-radius: 50%;
  transition: all 0.3s;
}
.coo-slider-nav svg, .coo-slider-nav img {
  width: 14px;
  height: auto;
}
.coo-slider-nav--prev {
  left: 6px;
  transform: rotate(90deg);
}
.coo-slider-nav--next {
  right: 6px;
  transform: rotate(-90deg);
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -6px;
  padding-bottom: 24px;
}
.filter-list__item {
  padding: 0 6px;
  margin-bottom: 12px;
}

.filter-btn {
  display: inline-block;
  font-size: 1.4rem;
  padding: 8px 16px 6px;
  line-height: 1em;
  font-weight: 700;
  background-color: #395EA7;
  border-radius: 50px;
}
.filter-btn.active, .filter-btn:hover {
  background-color: #3FB5C7;
}

.contact__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
}
.contact__header::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
  margin-bottom: 100px;
}
.contact__dec {
  position: absolute;
  top: -15px;
  left: 60px;
  width: 80px;
  height: auto;
}
.contact__form {
  margin-bottom: 40px;
}
.contact__info {
  max-width: 360px;
  padding-top: 16px;
  margin-bottom: 40px;
}
.contact__address {
  margin-bottom: 20px;
}
.contact__text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 80px;
}
.contact__email {
  display: flex;
  padding-top: 40px;
}

@media screen and (min-width: 992px) {
  .contact__header {
    margin-bottom: 90px;
    align-items: flex-end;
  }
  .contact__header::before {
    margin-bottom: 130px;
  }
  .contact__dec {
    top: -15px;
    left: 60px;
    width: 140px;
  }
  .contact__row {
    display: flex;
    justify-content: space-between;
  }
  .contact__form {
    order: 2;
    width: 62.5%;
  }
  .contact__email {
    padding-top: 80px;
    justify-content: flex-end;
  }
}
.social-media {
  display: flex;
  margin: 0 -4px;
}
.social-media__item {
  padding: 0 4px;
}
.social-media a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
  border-radius: 50%;
}
.social-media a img {
  transition: all 0.3s;
}
.social-media a:hover img {
  transform: scale(1.2);
}

.hero {
  padding-top: 120px;
  padding-bottom: 40px;
  position: relative;
}
.hero__bg {
  position: absolute;
  top: 0;
  right: 0;
}
.hero__breadcrumbs {
  margin-bottom: 40px;
  position: relative;
}
.hero__header {
  margin-bottom: 40px;
  position: relative;
}
.hero__header a {
  margin-top: 16px;
}
.hero__row {
  position: relative;
}
.hero__title {
  max-width: 780px;
}
.hero__desc {
  margin-bottom: 30px;
}
.hero__shadow {
  position: absolute;
  top: -80px;
  left: 0;
  transform: translateX(-50%);
}

@media screen and (min-width: 992px) {
  .hero {
    padding-top: 120px;
    padding-bottom: 100px;
  }
  .hero__shadow {
    top: -260px;
  }
  .hero__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .hero__header a {
    margin-top: 0;
  }
}
@media screen and (min-width: 1260px) {
  .hero {
    padding-top: 120px;
    padding-bottom: 100px;
  }
  .hero__breadcrumbs {
    margin-bottom: 80px;
  }
  .hero__row {
    display: flex;
  }
  .hero__desc {
    width: 50%;
    margin-bottom: 0;
  }
  .hero__info {
    width: 50%;
    padding-left: 80px;
  }
}
.tof-list {
  position: relative;
  padding-bottom: 60px;
  margin-bottom: 20px;
}
.tof-list__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.of-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}
.of-list__item {
  display: flex;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 32px;
}
@media screen and (min-width: 992px) {
  .of-list__item {
    width: 50%;
  }
}
.of-list__item--contact {
  margin-left: auto;
  align-items: flex-end;
  justify-content: center;
}

.of-box {
  display: block;
  width: 100%;
  padding: 30px 20px;
  position: relative;
  border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
  border-width: 1px;
  border-style: solid;
  background: linear-gradient(135deg, rgba(49, 148, 199, 0.2) 0%, rgba(37, 84, 177, 0.2) 100%);
}
@media screen and (min-width: 576px) {
  .of-box {
    padding: 40px;
  }
}
.of-box__title {
  margin-bottom: 20px;
  position: relative;
}
.of-box__text {
  position: relative;
}

.tof-contact-box {
  max-width: 480px;
  padding-top: 40px;
}
.tof-contact-box__title {
  margin-bottom: 30px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #4A4C69;
  font-size: 1.4rem;
  font-weight: 400;
}
.breadcrumbs__item {
  display: inline-flex;
}
.breadcrumbs__item::after {
  content: "";
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background-image: url(../../assets/next.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.breadcrumbs__item:last-child {
  color: #47C6DA;
}
.breadcrumbs__item:last-child::after {
  content: none;
}
.breadcrumbs a:hover {
  color: #47C6DA;
}

.to-about {
  padding-bottom: 80px;
}

.tab-list__item {
  margin-bottom: 20px;
}

.tab-box {
  width: 100%;
  border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
  border-width: 1px;
  border-style: solid;
  position: relative;
}
.tab-box:hover {
  background: linear-gradient(180deg, rgba(49, 148, 199, 0.2) 0%, rgba(37, 84, 177, 0.2) 100%);
}
.tab-box__header {
  padding: 30px 40px;
}
.tab-box__content {
  padding: 0 40px 40px;
}
.tab-box__line {
  margin-bottom: 40px;
}
.tab-box__col-1 {
  margin-bottom: 30px;
}
.tab-box__col-bg {
  padding: 20px 20px;
  background: rgba(49, 148, 199, 0.2);
  background: linear-gradient(180deg, rgba(49, 148, 199, 0.2) 0%, rgba(37, 84, 177, 0.2) 100%);
}
.tab-box__content {
  display: none;
}
.tab-box--simple .tab-box-btn {
  display: none !important;
}
.tab-box--toggle .tab-box__header {
  cursor: pointer;
}
.tab-box__row--t {
  padding-top: 40px;
}
.tab-box__actions {
  padding-top: 20px;
}
.tab-box__actions p {
  margin-bottom: 10px;
}
.tab-box.active .tab-box__content {
  display: block;
}
.tab-box--extra {
  background: linear-gradient(180deg, rgba(49, 148, 199, 0.2) 0%, rgba(37, 84, 177, 0.2) 100%);
}
.tab-box--extra::before {
  content: "";
  position: absolute;
  width: 66px;
  height: 80px;
  top: 0;
  left: 0;
  background-image: url(../../assets/vector.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-38px, 50%);
}

.tab-box-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.tab-box-header__title {
  min-width: 320px;
  width: 320px;
}
.tab-box-header__text {
  flex-grow: 1;
  max-width: 682px;
  padding: 0 20px;
}
.tab-box-header__btn {
  width: 120px;
  min-width: 120px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 576px) {
  .tab-box__actions {
    padding-top: 40px;
    display: flex;
    align-items: center;
  }
  .tab-box__actions p {
    margin-bottom: 0;
    margin-right: 30px;
  }
}
@media screen and (max-width: 991px) {
  .tab-box__header {
    padding: 30px 20px;
  }
  .tab-box__content {
    padding: 0 20px 30px;
  }
  .tab-box-header__title {
    min-width: 100%;
    width: 100%;
  }
  .tab-box-header__text {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
  .tab-box-header__btn {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .tab-box__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .tab-box__row .text {
    max-width: 520px;
  }
  .tab-box__col-1 {
    width: 50%;
    padding-right: 80px;
    margin-bottom: 0;
  }
  .tab-box__col-2 {
    width: 50%;
  }
  .tab-box__col-bg {
    padding: 30px 40px;
  }
}
@media screen and (max-width: 1259px) {
  .tab-box-header {
    flex-wrap: wrap;
  }
  .tab-box-header__title {
    width: 100%;
    margin-bottom: 10px;
  }
  .tab-box-header__text {
    padding-left: 0;
  }
}
.tp-about {
  margin-bottom: 40px;
  position: relative;
}
.tp-about__box {
  padding: 30px 20px;
  background: linear-gradient(-25deg, rgba(63, 181, 199, 0.2) 0%, rgba(57, 94, 167, 0.2) 100%);
  border-image: linear-gradient(180deg, #47C6DA 0%, #3194C7 100%) 1;
  border-width: 1px;
  border-style: solid;
}
.tp-about__box--simple {
  background: transparent;
}
.tp-about__info {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #47C6DA;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
}
@media screen and (min-width: 576px) {
  .tp-about__info {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 992px) {
  .tp-about__info {
    font-size: 1.8rem;
  }
}
.tp-about__text {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #47C6DA;
}
.tp-about__col-1 {
  margin-bottom: 30px;
}
.tp-about__cd {
  padding-top: 30px;
}

@media screen and (min-width: 992px) {
  .tp-about__box {
    padding: 40px;
  }
  .tp-about__row {
    display: flex;
    margin: 0 -40px;
  }
  .tp-about__col-1 {
    width: 50%;
    padding: 0 40px;
    margin-bottom: 0;
  }
  .tp-about__col-2 {
    width: 50%;
    padding: 0 40px;
  }
}
.coo-porfolio {
  padding-top: 40px;
  padding-bottom: 60px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}
.product-list__item {
  width: 50%;
  padding: 0 6px;
  margin-bottom: 12px;
}
.product-list__item.hide {
  display: none;
}
@media screen and (min-width: 992px) {
  .product-list__item {
    width: 33.33%;
  }
}
@media screen and (min-width: 1260px) {
  .product-list__item {
    width: 25%;
  }
}

@media screen and (min-width: 992px) {
  .product-list {
    margin: 0 -20px;
  }
  .product-list__item {
    padding: 0 20px;
    margin-bottom: 40px;
  }
}
.product-box {
  position: relative;
  padding-bottom: 130%;
}
.product-box__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: center;
}
.product-box__content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(49, 148, 199, 0.8);
  padding: 10px 8px;
  color: #fff;
  transition: all 0.3s;
}
.product-box__info {
  margin-bottom: 12px;
}
.product-box:hover .product-box__content {
  opacity: 1;
}

.product-cat-list__item {
  margin-bottom: 5px;
}
.product-cat-list__item span {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: 700;
  padding: 8px 8px 5px;
  background-color: #395EA7;
  border-radius: 50px;
}
@media screen and (max-width: 364px) {
  .product-cat-list__item span {
    font-size: 1rem;
  }
}

@media screen and (min-width: 576px) {
  .product-list {
    margin: 0 -10px;
  }
  .product-list__item {
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .product-box__content {
    padding: 20px;
  }
  .product-box__info {
    margin-bottom: 20px;
  }
  .product-cat-list {
    margin-top: 10px;
  }
  .product-cat-list__item span {
    font-size: 1.4rem;
    padding: 8px 16px 5px;
  }
}
.lang-switcher {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1.4rem;
  font-weight: 700;
}
.lang-switcher .lang-item a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.lang-switcher .lang-item a:hover {
  color: #3FB5C7;
}
.lang-switcher .lang-item.current-lang {
  border: 1px solid #3FB5C7;
  border-radius: 50%;
  overflow: hidden;
}

.header {
  width: 100%;
  position: absolute;
  z-index: 12;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}
.header__row {
  display: flex;
  align-items: center;
  height: 100px;
}
.header__branding {
  margin-right: 80px;
  position: relative;
  z-index: 2;
}
.header__nav {
  display: flex;
  align-items: flex-end;
}
.header__actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1259.99px) {
  .header {
    height: 100vh;
    min-height: 600px;
    pointer-events: none;
  }
  .header__row {
    pointer-events: all;
  }
  .header__langs {
    position: absolute;
    bottom: 60px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
    transform: translateX(50vw);
  }
}
@media screen and (min-width: 1260px) {
  .header__langs {
    margin-right: -70px;
    margin-left: 30px;
  }
}
@media screen and (min-width: 1560px) {
  .header__langs {
    margin-right: -120px;
    margin-left: 40px;
  }
}
.hamburger {
  display: none;
  width: 40px;
  height: 40px;
  z-index: 100;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
  overflow: hidden;
}
.hamburger svg {
  position: absolute;
  right: -15%;
  height: 100%;
  transform-origin: top right;
}
.hamburger path {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
}
.hamburger path:nth-child(1), .hamburger path:nth-child(3) {
  --total-length: 126.64183044433594;
}
.hamburger path:nth-child(2) {
  --total-length: 70;
}

@media screen and (max-width: 1259.99px) {
  .nav-toggled .header {
    box-shadow: 0px 10px 30px 0px rgba(71, 198, 218, 0.2);
  }
  .nav-toggled .header__langs {
    transform: translateX(-50%);
    opacity: 1;
    pointer-events: all;
  }
  .nav-toggled .nav {
    transform: translateX(0);
    opacity: 1;
  }
  .nav-toggled .hamburger path {
    stroke: #fff;
  }
  .nav-toggled .hamburger path:nth-child(1), .nav-toggled .hamburger path:nth-child(3) {
    --length: 22.627416998;
    --offset: -94.1149185097;
  }
  .nav-toggled .hamburger path:nth-child(2) {
    --length: 0;
    --offset: -50;
  }
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    background-color: #121326;
    opacity: 0;
    transform: translateX(100%);
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hamburger {
    display: block;
  }
}
.menu {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 0 -18px;
}
.menu__item {
  position: relative;
  padding: 0 18px;
}
.menu__item.active {
  color: #3FB5C7;
}
.menu__link {
  display: inline-flex;
  position: relative;
  padding: 10px 0;
}
.menu__link::after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 46px;
  height: 1px;
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
}
.menu__link:hover {
  color: #3FB5C7;
}

@media screen and (max-width: 1259.99px) {
  .menu {
    flex-direction: column;
  }
  .menu__item {
    margin-bottom: 30px;
  }
  .menu__link::after {
    left: 50%;
    transform: translateX(-50%);
  }
}
.th-hero {
  padding-top: 140px;
  position: relative;
}
.th-hero__bg {
  position: absolute;
  top: -40px;
  right: -60px;
  max-width: 100%;
  height: auto;
}
.th-hero__pic {
  display: none;
}
.th-hero__title {
  margin-bottom: 3rem;
}

.th-hero-pic {
  animation: rotating 300s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 992px) {
  .th-hero {
    padding-top: 160px;
  }
  .th-hero__bg {
    top: -240px;
    left: 50%;
    right: auto;
    transform: translateX(-55%);
  }
  .th-hero__row {
    display: flex;
    align-items: center;
    margin: 0 -4rem;
  }
  .th-hero__pic {
    display: block;
    width: 50%;
    padding: 0 4rem;
  }
  .th-hero__desc {
    width: 50%;
    padding: 0 4rem;
  }
  .th-hero__title {
    margin-bottom: 4rem;
  }
}
.th-about {
  position: relative;
}
.th-about__bg {
  position: absolute;
  top: 340px;
  left: 0;
  width: 100%;
  height: auto;
}
.th-about__container {
  position: relative;
  padding-top: 140px;
}
@media screen and (max-width: 575px) {
  .th-about__container {
    padding-top: 100px;
  }
}
.th-about__dec {
  position: absolute;
  top: -80px;
  right: 0;
  width: 168px;
}
@media screen and (max-width: 575px) {
  .th-about__dec {
    top: -10px;
    right: -20px;
    width: 100px;
  }
}
@media screen and (min-width: 992px) {
  .th-about__dec {
    top: 0;
  }
}
.th-about__header {
  max-width: 960px;
  margin: 0 auto;
  position: relative;
}
.th-about__list {
  padding-top: 40px;
  padding-bottom: 60px;
  max-width: 1220px;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .th-about__list {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}

.footer {
  position: relative;
}
.footer__up {
  position: absolute;
  right: 10px;
  top: -30px;
}
@media screen and (min-width: 576px) {
  .footer__up {
    top: 26px;
  }
}
@media screen and (min-width: 1260px) {
  .footer__up {
    right: 0;
  }
}

.footer-copyright {
  position: relative;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  color: #3FB5C7;
}
@media screen and (max-width: 1259px) {
  .footer-copyright {
    padding-right: 30px;
  }
}
.footer-copyright::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgb(63, 181, 199) 0%, rgb(57, 94, 167) 100%);
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 765px) {
  .footer-copyright {
    padding: 30px 0;
    flex-direction: column;
  }
  .footer-copyright__info {
    margin-bottom: 16px;
  }
}